import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Privacy = () => (
  <Layout pageInfo={{
    pageName: "Privacy Policy" +
      "",
  }}>
    <SEO title="Privacy Policy page"
         description="Privacy Policy for KnowEthereum"
         keywords={[`Ethereum`, `Privacy Policy KnowEthereum`]}/>
    <Container fluid className="px-5 py-5 about-container">
      <Row noGutters>
        <Col className="col-md-10 offset-md-1">
          <Container fluid>
            <Row noGutters>
              <h1 className="hero-title">
                Privacy Policy for KnowEthereum
                <hr className="hero-separator"/>
              </h1>
            </Row>
            <Row noGutters>
              <p className="mt-4 hero-text about-text">
                At KnowEthereum, accessible from KnowEthereum.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by KnowEthereum and how we use it.
                If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. 
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in KnowEthereum. This policy is not applicable to any information collected offline or via channels other than this website.
              </p>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                Consent
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <p className="mt-4 hero-text about-text">
                By using our website, you hereby consent to our Privacy Policy and agree to its terms. The information about you is only collected if you voluntarily provide it by accepting our cookies consent, contacting us or suggesting a resource. Without your consent, we do not collect any information about you.
              </p>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                Cookies and Analytics
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <p className="mt-4 hero-text about-text">
                We use{' '}
                <a href="https://analytics.google.com/"
                       target='_blank' rel='noopener noreferrer'
                       aria-label="google analytics service"
                       className="text-dark">
                  Google Analytics service
                </a>{' '}provided by Google to track and analyze how you interact with this website. 
                With your consent, this service inserts{' '}
                <a href="https://en.wikipedia.org/wiki/HTTP_cookie"
                       target='_blank' rel='noopener noreferrer'
                       aria-label="cookie"
                       className="text-dark">
                  cookie
                </a>{' '}and associates UserID with your browser to track various events, analyze usage data, demographics, system information, pageviews, session durations, etc. 
                You can read more about how and what data Google Analytics collects by visiting its official page and by reading{' '}
                <a href="https://policies.google.com/technologies/cookies"
                       target='_blank' rel='noopener noreferrer'
                       aria-label="google cookies policy"
                       className="text-dark">
                  Google cookies policy
                </a>{' '}.
                The information is collected anonymously, it is stored and protected by Google. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. 
              </p>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                How we use your information
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <span className="mt-4 hero-text about-text">
                We use the information we collect in various ways, including to:
                <ul>
                    <li>
                    Provide, operate, and maintain our webste.
                    </li>
                    <li>
                    Improve, personalize, and expand our webste.
                    </li>
                    <li>
                    Understand and analyze how you use our website.
                    </li>
                    <li>
                    Develop new products, services, features, and functionality.
                    </li>
                    <li>
                    Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes.
                    </li>
                    <li>
                    Send you emails.
                    </li>
                    <li>
                    Find and prevent fraud.
                    </li>
                </ul>
              </span>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                Third Party Privacy Policies
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <p className="mt-4 hero-text about-text">
                KnowEthereum's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-parties for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
              </p>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                CCPA Privacy Rights
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <span className="mt-4 hero-text about-text">
                Under the CCPA, among other rights, California consumers have the right to:
                <ul>
                    <li>
                    Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
                    </li>
                    <li>
                    Request that a business delete any personal data about the consumer that a business has collected.
                    </li>
                    <li>
                    Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
                    </li>
                </ul>
              </span>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                GDPR Data Protection Rights
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <span className="mt-4 hero-text about-text">
                We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                <ul>
                    <li>
                    The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
                    </li>
                    <li>
                    The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                    </li>
                    <li>
                    The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                    </li>
                    <li>
                    The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                    </li>
                    <li>
                    The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                    </li>
                    <li>
                    The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                    </li>
                </ul>
              </span>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                Children's Information
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <p className="mt-4 hero-text about-text">
                Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                KnowEthereum does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
              </p>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                Opt-out
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <p className="mt-4 hero-text about-text">
                Once you agree with our cookies consent, your consent is valid for two years. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. 
                Alternatively, you may download and install{' '}
                    <a href="https://tools.google.com/dlpage/gaoptout"
                       target='_blank' rel='noopener noreferrer'
                       aria-label="google plugin"
                       className="text-dark">
                  Google plugin
                </a>{' '}to disable Google Analytics.
              </p>
            </Row>
            <b>Last updated on July 13th, 2020</b>
          </Container>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Privacy
